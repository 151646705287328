import { uuid } from '@signal24/vue-foundation';
import { OtelState } from './otel';

export class SharedState {
    constructor() {
        OtelState.installId = `b/${this.browserId}`;
    }

    get browserId() {
        let browserId = window.localStorage.getItem('zs:browserId');
        if (!browserId) {
            browserId = uuid();
            window.localStorage.setItem('zs:browserId', browserId);
        }
        return browserId;
    }
}
